@tailwind base;
@tailwind components;
@tailwind utilities;

.game-table__row {
  grid-template-areas:
    "name name name name name name name"
    "team conf div pos ht age no";
}

@media (min-width: 768px) {
  .game-table__body .game-table__row {
    /* background: 0 0; */
    grid-template-columns: 250px 100px 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "name team conf div pos ht age no";
  }
}

.game-table__body .game-table__row .game-table__cell:first-child {
  grid-area: name;
}

.game-table__body .game-table__row .game-table__cell:nth-child(2) {
  grid-area: team;
}

.game-table__body .game-table__row .game-table__cell:nth-child(3) {
  grid-area: conf;
}

.game-table__body .game-table__row .game-table__cell:nth-child(4) {
  grid-area: div;
}

.game-table__body .game-table__row .game-table__cell:nth-child(5) {
  grid-area: pos;
}

.game-table__body .game-table__row .game-table__cell:nth-child(6) {
  grid-area: ht;
}

.game-table__body .game-table__row .game-table__cell:nth-child(7) {
  grid-area: age;
}

.game-table__body .game-table__row .game-table__cell:nth-child(8) {
  grid-area: no;
}

.sv__text-question {
  font-size: 16px !important;
}
